<template>
  <div>
    <a
      data-toggle="tooltip"
      data-placement="top"
      title="Switch user"
      @click="fnSwitchUser()"
      ><i class="far fa-arrow-alt-circle-right text-info mr-2"></i>
    </a>
  </div>
</template>
<script>
import * as messageService from "../../../services/message.service";
import * as authService from "../../../services/auth.service";

export default {
  name: "ModelUserEdit",
  props: ["userId"],
  components: {},
  data() {
    return {
      errors: {},
      id: "",
    };
  },
  methods: {
    fnSwitchUser: async function () {
      try {
        const response = await authService.switchUserLogin({
          userId: this.userId,
        });
        this.errors = {};

        if (
          response.token_scope == "admin" ||
          response.token_scope == "staff"
        ) {
          this.$router.push({ name: "dashboardAdmin" });
        } else if (response.token_scope == "individual-user") {
          this.$router.push({ name: "dashboardIU" });
        } else if (response.token_scope == "corporate-manager") {
          this.$router.push({ name: "dashboardCM" });
        } else if (response.token_scope == "corporate-staff") {
          this.$router.push({ name: "dashboardCM" });
        } else if (response.token_scope == "corporate-customer") {
          this.$router.push({ name: "dashboardCC" });
        } else if (response.token_scope == "partner") {
          this.$router.push({ name: "dashboardPartner" });
        } else if (response.token_scope == "partner-staff") {
          this.$router.push({ name: "dashboardPartner" });
        }
      } catch (error) {
        switch (error.response.status) {
          case 401:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Switch user login",
              error.response.data.message
            );
            break;
          case 403:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Switch user login",
              error.response.data.message
            );
            break;
          case 422:
            this.errors = error.response.data.errors;
            messageService.fnSweetAlertErrorToast(
              "Switch user login",
              error.response.data.message
            );
            break;
          case 500:
            messageService.fnSweetAlertErrorToast(
              "Switch user login",
              error.response.data.message
            );
            break;
          default:
            messageService.fnSweetAlertErrorToast(
              "Switch user login",
              "Something wrong, please try again!"
            );
            break;
        }
      }
    },
  },
};
</script>